<template>

    <div>

      <div class="item__row item__ac">
          <h2>{{$route.query.name}}</h2>
          <v-btn
              small
              class="mx-2"
              fab
              dark
              color="indigo"
              @click="chooseTypeFunction(1)"
              >
              <v-icon dark>
                  mdi-plus
              </v-icon>
          </v-btn>
      </div>

      <v-category ref="modal" ></v-category>

      <v-data-table
          :headers="headers"
          :items="items"
          :page="page"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalPage"
      >  

       
      <template v-slot:item.data="{ item  }">
          <p class="mb-2" v-if="JSON.parse(item.data)">На рус: {{JSON.parse(item.data).title}}</p>       
          <p class="mb-2" v-if="JSON.parse(item.data)">На каз: {{JSON.parse(item.data).title_kaz}}</p>
          <p class="mb-6" v-if="JSON.parse(item.data)">На анг: {{JSON.parse(item.data).title_eng}}</p>
          
          <div class="item__row item__ac table__description">
              <p class="mr-2">Описание на рус: </p>
              <p class="mb-0 table__description__value" v-html="JSON.parse(item.data).description"></p>
          </div>

           <div class="item__row item__ac table__description">
              <p class="mr-2">Описание на каз: </p>
              <p class="mb-0 table__description__value" v-html="JSON.parse(item.data).description_kaz"></p>
          </div>

           <div class="item__row item__ac table__description">
              <p class="mr-2">Описание на анг: </p>
              <p class="mb-0 table__description__value" v-html="JSON.parse(item.data).description_eng"></p>
          </div>
      </template>

      <template v-slot:item.date="{ item  }">
          <p class="table__date"> {{item.date}}</p>   
      </template>
      <template v-slot:item.created_at="{ item  }">
          <p class="table__date">{{formatDate(item.created_at)}}</p>
      </template>
      <template v-slot:item.updated_at="{ item  }">
          <p class="table__date">{{formatDate(item.updated_at)}}</p>
      </template>
      <template v-slot:item.action="{ item  }" >
          <div class="table__row">

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <i

                    class="mdi mdi-pencil "
                    @click="show(item,JSON.parse(item.data),item.files, item.files_pdf)"
                    v-bind="attrs"
                    v-on="on"
                  ></i>
                </template>
                <span>Редактировать</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <i
                    style="margin: 10px;"
                    class="mdi mdi-trash-can-outline"
                    @click="openDeleteModal(item.id)"
                    v-bind="attrs"
                    v-on="on"
                  ></i>
                </template>
                <span>Удалить</span>
              </v-tooltip>

          </div>
      </template>
      </v-data-table>

      <v-dialog v-model="destroyModal" width="800">
        <v-card class="pa-6">
          <h3 class="mb-4">Удалить запись</h3>
          <v-btn
              class="mr-4"
              type="submit"
              depressed
              color="primary"
              @click="deleteItem()"
          >
            Да
          </v-btn>

          <v-btn
              depressed
              color="default"
              @click="destroyModal=false"
          >
            Отмена
          </v-btn>
        </v-card>
      </v-dialog>
      <v-dialog v-model="openModal" width="1200" persistent>
          <v-card class="pa-6">
              <v-form
                  @submit.prevent="callFunction()"
                  ref="form"
                  class="sign__page__block"
              >

              <h3 class="mb-4" v-if="type==1">Создать </h3>
              <h3 class="mb-4" v-else>Редактировать </h3>
              

              


              <div class="item__column">
                  <v-text-field
                      v-model="title"
                      label="Название"
                      required
                      outlined
                      class="input"
                      :rules="nameRules"
                  ></v-text-field>
              </div>
      
              <p class="mb-2">Описание на русском</p>
              <vue-editor 
                  use-custom-image-handler 
                  v-model="description"
                    useCustomImageHandler
                  @image-added="handleImageAdded"
                   :editorOptions="editorSettings"

                :rules="descriptionRules"  class="mb-4" />
              <div class="item__column">
                  <v-text-field
                      v-model="title_eng"
                      label="Название английском"
                      required
                      outlined
                      class="input"
                      :rules="nameRules"
                  ></v-text-field>
              </div>

              <div class="item__column">
                  <p class="mb-2">Описание на английском</p>
                  <vue-editor v-model="description_eng"  :rules="descriptionRules"   
                  use-custom-image-handler 
                  useCustomImageHandler
                  @image-added="handleImageAdded"
                  :editorOptions="editorSettings"
                  class="mb-4" />
              </div>
           

              <div class="item__column">
                  <v-text-field
                      v-model="title_kaz"
                      label="Название казахском"
                      required
                      outlined
                      class="input"
                      :rules="nameRules"
                  ></v-text-field>
              </div>

        
              <div class="item__column">
                  <p class="mb-2">Описание на казахском</p>
                  <vue-editor v-model="description_kaz"  :rules="descriptionRules"   
                  use-custom-image-handler 
                  useCustomImageHandler
                  @image-added="handleImageAdded"
                  :editorOptions="editorSettings"
                  class="mb-4" />
              </div>

              <div class="item__column date__picker" @click="dateModal=true">
                  
                  <p v-if="!date">Дата</p>
                  <p v-else>{{ date }}</p>
              </div>

              <div class="item__column">
                  <v-text-field
                      v-model="keywords"
                      label="Ключевые слова"
                      required
                      outlined
                      class="input mt-3"
                      
                      :rules="nameRules"
                      
                  ></v-text-field>
              </div>

              <div>
                   <v-file-input
                      chips
                      counter
                      multiple
                      show-size
                      small-chips
                      truncate-length="15"
                      v-model="files"
                  ></v-file-input>
              </div>
              <div class="item__row">
                  <div v-for="file in uploadedFiles" :key="file.id" class="item__row item__ac pointer mr-8 mb-6 images">
                      <!-- <p class="mr-2 mb-0">{{file.path.split('/')[file.path.split('/').length-1]}}</p> -->
                      <img class="mr-2" :src='"https://api.turkic-inscriptions.crocos.kz/"+file.path' />
                      <i class="mdi mdi-trash-can-outline" @click="removeFile(file.id)"></i>
                  </div>
              </div> 

              <div>
                  <label>Книги</label>
                   <v-file-input
                      chips
                      counter
                      multiple
                      show-size
                      small-chips
                      truncate-length="15"
                      v-model="files_pdf"
                  ></v-file-input>
              </div>
              <div class="item__row">
                  <div v-for="file in uploadedFilesPdf" :key="file.id" class="item__row item__ac pointer mr-8 mb-6 images">
                      <!-- <p class="mr-2 mb-0">{{file.path.split('/')[file.path.split('/').length-1]}}</p> -->
                      <a :href='"https://api.turkic-inscriptions.crocos.kz/"+file.path' target="_blank">Файл</a>
                      <i class="mdi mdi-trash-can-outline" @click="removeFile(file.id)"></i>
                  </div>
              </div> 

              


               <v-btn
                  type="submit"
                  depressed
                  color="primary"
                  class="mr-4"
                  :disabled="isActive"
                  >
                 Сохранить изменения
              </v-btn>

              <v-btn
                  depressed
                  color="default"
                  :disabled="isActive"
                  @click="openModal=false"
              >
                Отмена
              </v-btn>
              </v-form>
          </v-card>
      </v-dialog>

      <v-dialog v-model="dateModal" width="280">
          <v-card>
              <v-date-picker v-model="date" locale="ru-in"></v-date-picker>
          </v-card>
      </v-dialog>
    </div>

</template>

<script>
import { VueEditor,Quill } from 'vue2-editor'
import ImageResize from 'quill-image-resize-vue';
import { ImageDrop } from 'quill-image-drop-module';
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

export default {
props: [
],
name: "News",
components: {
  VueEditor
},
data() {
  return {
      editorSettings: {
          modules: {
          imageDrop: true,
          imageResize: {},
          }
      },
      date: '',
      dateModal: false,
      result: '',
      customToolbar: [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          // ["image", "code-block"]
      ],
      artefact_type: '',
      categories: [],
      category_name: '',
      categoryModal: false,
      totalPage: null,
      numberOfPages: null,
      loading: false,
      items: [],
      openModal: false,
      isActive: false,
      page: 0,
      options: {
          itemsPerPage: 100,
          page: 1,
      },
      destroyModal: false,
          nameRules: [
              v => !!v || 'Заполните поле'
          ],
          descriptionRules: [
              v => !!v || 'Заполните поле'
          ],
      files: [],
      files_pdf: [],
      type: 0,
      newsId:'',
      me: null,
      selectedUser: null,
      uploadedFiles: [],
      uploadedFilesPdf: [],
      title: null,
      descriptionQ: null,
      description: '',
      title_eng: null,
      title_kaz: null,
      description_eng: null,
      description_kaz: null,
      keywords:null,
      headers: [
          {
          text: "№",
          align: "start",
          sortable: false,
          value: "id",
          },
          { text: "Данные", value: "data" },
          { text: "Дата", value: "date" },
          { text: "Дата создание", value: "created_at" },
          { text: "Дата редактирование", value: "updated_at" },
          { text: 'Действие', value: 'action' },
      ],
  };
},
methods: {
          handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
              var formData = new FormData();
              formData.append("file", file);
              this.$axios({
                  url: this.$API_URL +
                  this.$API_VERSION +'file',
                  method: "POST",
                  data: formData,
                  headers: {
                      
                      "Content-Type": "multipart/form-data",
                  },
              })
              .then(result => {
                  const url = this.$API_MAIN+result.data; // Get url from response // Get url from response
                  Editor.insertEmbed(cursorLocation, "image", url);
                  resetUploader();
              })
              .catch(err => {
                  console.log(err);
              });
      },
        onUpdate ({ getJSON, getHTML }) {
          this.json = getJSON()
          this.html = getHTML()
          console.log('html', this.html)
        },
        openChildModal() {
          this.$refs.modal.openCategory=true;
        },
        formatDate(date) {
              if(date) {
                  let d = date.split(' ')[0].split('-');
                  let time = date.split(' ')[1].split(':');
                  return d[2]+'-'+d[1]+'-'+d[0]+' '+time[0]+':'+time[1];
              }
          },
      removeFile(fileId) {
          this.$axios({
              method: "delete",
              url:
              this.$API_URL +
              this.$API_VERSION +
              "page/file/"+fileId,
              headers: {
                  
              },
          })
          .then((response) => {
              this.$toast.open({
                  message: response.data.message,
                  type: "success",
                  position: "bottom",
                  duration: 4000,
                  queue: true,
              });
              this.fetch();
              this.openModal = false;
          })
          .catch((error) => {
              console.warn(error);
          });
      },
      getUser() {
          this.$axios({
              method: "get",
              url:
              this.$API_URL +
              this.$API_VERSION +
              "me",
              headers: {
              
              },
          })
          .then((response) => {
              this.me = response.data;
          })
          .catch((error) => {
              console.warn(error);
          });
      },
    chooseTypeFunction(type) {
        this.type = type;
        this.openModal = true;
        this.uploadedFiles = [];
        this.getCategory();
    },
    callFunction() {
        this.type==1?this.create():this.update();
    },
    create() {
          let obj = {
              title: this.title,
              description: this.description,
              title_eng: this.title_eng,
              description_eng: this.description_eng,
              title_kaz: this.title_kaz,
              description_kaz: this.description_kaz,
              type: this.$route.query.type,
              keywords: this.keywords
          };
          this.$refs.form.validate();
          this.isActive = true;
          this.createData(obj,this.files, this.files_pdf);
          this.$refs.form.reset();
      },
      fetch() {
          this.loading = true;
          // let url = "page?type="+this.$route.query.type;
          // if(options)
          let link = "page?type="+this.$route.query.type+'&per_page='+this.options.itemsPerPage+'&page='+this.options.page;
          this.$axios({
          method: "get",
          url:
              this.$API_URL +
              this.$API_VERSION +
              link,
              headers: {
                  
              },
          })
          .then((response) => {
              this.items = response.data.data;
              this.loading = false;
              this.numberOfPages = response.data.meta.total;
              this.totalPage = response.data.meta.total;
              this.openModal = false;
              this.type = 0;
          })
          .catch((error) => {
          console.log(error);
          });
      },
      createData(obj,files, files_pdf) {
          console.log(this.artefact_type);
          let contractForm = new FormData();
          contractForm.append("data", JSON.stringify(obj));
          contractForm.append("type", this.$route.query.type);
          contractForm.append("category_id", typeof this.artefact_type=='object'?this.artefact_type.id:this.artefact_type);
          contractForm.append("date", this.date?this.date:'');
          if(files)
              for (var i = 0; i < files.length; i++) {
                  contractForm.append("files[]", files[i]);
              }  
          
          if(files_pdf) {
              for (var ip = 0; ip < files_pdf.length; ip++) {
                  contractForm.append("files_pdf[]", files_pdf[ip]);
              }
          }
              
          
         
          this.$axios
              .post(this.$API_URL + this.$API_VERSION + "page", contractForm, {
              headers: {
                  
                  "Content-Type": "multipart/form-data",
              },
          })
          .then((response) => {
              this.result = response.data;
              this.openModal = false;
              this.showMessage('success',response.data.message);
              this.fetch(this.options);
          })
          .catch((error) => {
              if (error.response && error.response.status == 422) {
                 this.showMessage('error',"Не правильно заполнено");
              }
          });
      },
      getCategory() {
          this.$axios
              .get(this.$API_URL + this.$API_VERSION + "category?type="+this.$route.query.type, {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem("access_token")}`
              },
          })
          .then((response) => {
              this.categories = response.data;
          })
          .catch((error) => {
              if (error.response && error.response.status == 422) {
                 this.showMessage('error',"Не правильно заполнено");
              }
          });
      },  
      createCategory() {
          let contractForm = new FormData();
          contractForm.append("type", this.$route.query.type);
          contractForm.append("name", this.category_name);
          this.$axios
              .post(this.$API_URL + this.$API_VERSION + "category", contractForm, {
              headers: {
                  
                  "Content-Type": "multipart/form-data",
              },
          })
          .then((response) => {
              this.openModal = false;
              this.showMessage('success',response.data.message);
              this.fetch(this.options);
              this.getCategory();
          })
          .catch((error) => {
              if (error.response && error.response.status == 422) {
                 this.showMessage('error',"Не правильно заполнено");
              }
          });
      },  

      show(page,item,files, files_pdf) {
          this.artefact_type = this.categories.filter(category=>category.id==page.category_id).length>0?this.categories.filter(category=>category.id==page.category_id)[0].id:'';
          this.id = page.id;
          this.openModal = true;
          this.title = item.title;
          this.description =  item.description;
          this.title_eng = item.title_eng;
          this.title_kaz = item.title_kaz;
          this.description_eng = item.description_eng;
          this.description_kaz = item.description_kaz;
          this.date = page.date;
          this.keywords = item.keywords;
          this.uploadedFiles = files;
          this.uploadedFilesPdf = files_pdf;
      },
      makeJson(item) {
          if(item)
              return JSON.parse(item);
      },
      openDeleteModal(id) {
        this.destroyModal = true;
        this.selectedUser = id;
      },
      deleteItem() {
          this.$axios({
          method: "delete",
          url:
              this.$API_URL +
              this.$API_VERSION +
              "page/"+this.selectedUser,
              headers: {
                  
              },
          })
          .then((response) => {
              this.title = response.data.title;
              this.description = response.data.description;
              this.fetch();
              this.destroyModal = false;
          })
          .catch((error) => {
          console.log(error);
          });
      },
      update() {
          
              let obj = {
                  title: this.title,
                  description: this.description,
                  title_eng: this.title_eng,
                  description_eng: this.description_eng,
                  title_kaz: this.title_kaz,
                  description_kaz: this.description_kaz,
                  type: this.$route.query.type,
                  keywords: this.keywords
              };
              this.isActive = true;
              this.$axios.put(this.$API_URL + this.$API_VERSION + "page/"+this.id,
                  {
                      data: JSON.stringify(obj),
                      type: this.$route.query.type,
                      category_id: this.artefact_type,
                      date: this.date?this.date:''
                  }, {
                  headers: {
                      
                  },
              })
              .then((response) => {
                  this.showMessage('success',response.data.message);
                  if(this.files.length>0) {
                      this.uploadFiles(this.files,this.id);
                  }else if (this.files_pdf.length > 0) {
                      this.uploadFiles2(this.files_pdf, this.id);
                  }
                  else {
                      this.fetch();

                  }
              })
              .catch((error) => {
                  if (error.response && error.response.status == 422) {
                     this.showMessage('error',"Не правильно заполнено");
                  }
              });   
      },
      uploadFiles(files,id) {
          let contractForm = new FormData();
          for (var i = 0; i < files.length; i++) {
              contractForm.append("files[]", files[i]);
          }
          this.$axios
              .post(this.$API_URL + this.$API_VERSION + "page/files/"+ id, contractForm, {
              headers: {
                  
                  "Content-Type": "multipart/form-data",
              },
          })
          .then((response) => {
              this.showMessage('success',response.data.message);
              this.fetch();
          })
          .catch((error) => {
              if (error.response && error.response.status == 422) {
                  this.showMessage('error',"Не правильно заполнено");
              }
          });
      },

      uploadFiles2(files,id) {
          let contractForm = new FormData();
          for (var i = 0; i < files.length; i++) {
              contractForm.append("files_pdf[]", files[i]);
          }
          this.$axios
              .post(this.$API_URL + this.$API_VERSION + "page/files/"+ id, contractForm, {
              headers: {
                  
                  "Content-Type": "multipart/form-data",
              },
          })
          .then((response) => {
              this.showMessage('success',response.data.message);
              this.fetch();
          })
          .catch((error) => {
              if (error.response && error.response.status == 422) {
                  this.showMessage('error',"Не правильно заполнено");
              }
          });
      },

      showMessage(type,text) {
           this.$toast.open({
              message: text,
              type: type,
              position: "bottom",
              duration: 4000,
              queue: true,
          });
      }
},
mounted() {
    this.fetch();
    this.getCategory();
    
},
beforeMount() {
},
watch: {
  options: {
    handler(val) {
      if (val.itemsPerPage < 0) {
        val.itemsPerPage = this.totalPage;
          this.fetch()
      } else {
          this.fetch();
      }
    },
  },
  openModal(val) {
      if(!val) {
          this.title = '';
          this.description = '';
          this.title_eng = '';
          this.description_eng = '';
          this.title_kaz = '';
          this.description_kaz = '';
          this.date = '';
          this.keywords = '';
          this.isActive = false;
          this.files_pdf = '';
      }
  },

  deep: true,

},
};
</script>


<style lang="scss">
.news__list {
  img {
      width: 100px;
      height: 100px;
      object-fit: cover;
  }
}

.date__picker {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #9e9e9e;
  p {
      margin-bottom: 0 !important;
  }
}

.table__date {
  min-width: 100px;
}
.table__row {
  min-width: 100px;
}

</style>
